<template>
  <v-app>
    <section class="mt-16 analiz">
      <v-card max-width="50%" class="mx-auto pa-10">
        <v-select
          :items="categories"
          item-text="name"
          item-value="id"
          return-object
          v-model="selectedLessonGroup"
          placeholder="Select Lesson Type"
        ></v-select>
        <v-slider
          v-if="filteredList"
          v-model="editedItem.difficulty"
          thumb-color="red"
          color="red"
          thumb-label="always"
        ></v-slider>
        <v-btn
          @click="createSoril = !createSoril"
          class="bg-gradient-success white--text mb-4"
          v-if="filteredList"
          >Create Soril</v-btn
        >
        <v-data-table
          :headers="headers"
          :items="filteredList"
          :items-per-page="-1"
          :sort-by="'correctPercent'"
          :sort-desc="true"
          v-if="filteredList"
          class="elevation-1"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.sorilIndex }}</td>
              <td>{{ props.item.questionIndex }}</td>

              <td
                :style="
                  'A' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
              >
                {{ props.item.A }}
              </td>
              <td
                :style="
                  'B' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
              >
                {{ props.item.B }}
              </td>
              <td
                :style="
                  'C' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
              >
                {{ props.item.C }}
              </td>
              <td
                :style="
                  'D' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
              >
                {{ props.item.D }}
              </td>
              <td
                :style="
                  'E' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
              >
                {{ props.item.E }}
              </td>
              <td>{{ props.item.correctPercent }}%</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <table style="width: 100% !important;font-size: 13px; border: 1px solid black " v-if="assignments">
                <thead>
                    <tr>
                        <th>Soril No</th>
                        <th>No</th>
                        <th v-for="(row, aindex) in [1, 2, 3, 4, 5]" :key="'aasdasd' + aindex">
                        <td style=" border: none; font-weight: normal" class="py-4 px-11">
                            {{ String.fromCharCode(94 + aindex + 3).toUpperCase() }}
                        </td>
                        </th>
                        <td class="px-10">Correct %</td>
                    </tr>
                </thead>
                <tbody v-for="(assignment, aindex) in assignments" :key="'assignment' + aindex" style="border-top: 1px solid black">
                    <tr v-for="(result, rindex) in assignment.results" :key="'result' + rindex">
                        <td style="width: 60px" class="pa-3">
                            {{ "C" + (aindex + 1) }}
                        </td>
                        <td style="width: 60px" class="pa-3">
                            {{ rindex + 1 }}
                        </td>
                        <td :style="result.correctAnswer == 'A' ? 'background-color: #98ec2d' : ''" v-text="result.A ? result.A : 0">
                        </td>
                        <td :style="result.correctAnswer == 'B' ? 'background-color: #98ec2d' : ''" v-text="result.B ? result.B : 0">
                        </td>
                        <td :style="result.correctAnswer == 'C' ? 'background-color: #98ec2d' : ''" v-text="result.C ? result.C : 0">
                        </td>
                        <td :style="result.correctAnswer == 'D' ? 'background-color: #98ec2d' : ''" v-text="result.D ? result.D : 0">
                        </td>
                        <td :style="result.correctAnswer == 'E' ? 'background-color: #98ec2d' : ''" v-text="result.E ? result.E : 0">
                        </td>
                        <td>{{ _getSum(result) }}%</td>
                    </tr>
                </tbody>
            </table> -->
      </v-card>
      <v-dialog v-model="createSoril" max-width="500px" v-if="editedItem">
        <v-card class="py-4">
          <v-card-title class="headline">
            Шинэ сорил/шалгалт үүсгэx
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    autofocus
                    v-model.trim="editedItem.name"
                    label="Нэр"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-radio-group
                  v-model="editedItem.assignmentType"
                  :rules="[rules.required]"
                  disabled
                >
                  <v-radio
                    :value="rt.value"
                    v-for="(rt, rtindex) in assignmentsTypes"
                    :key="'assignmentTypes' + rtindex"
                  >
                    <template v-slot:label>
                      <div>
                        {{ rt.name }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    return-object
                    :items="categories"
                    item-text="name2"
                    item-value="id"
                    v-model="selectedLessonGroup"
                    disabled
                    label="Ангилалт сонгоx"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model.number="editedItem.duration"
                    label="Xугацаа минутаар"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model.trim="editedItem.shortName"
                    label="Товчилсон нэр"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field v-model.trim="editedItem.code" label="Код" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model.number="editedItem.amountOfQuestions"
                    placeholder="Question Amount"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    disabled
                    label="Difficulty Filter"
                    v-model="editedItem.difficulty"
                    suffix="%"
                    type="number"
                    v-bind:max="max"
                    v-bind:min="min"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                  <v-textarea
                    v-model="editedItem.description"
                    outlined
                    label="Тэмдэглэл бичиx"
                    color="rgba(0,0,0,.6)"
                    class="
                      font-size-input
                      border
                      text-light-input
                      border-radius-md
                      mt-2
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text>
            <v-container>
              <span class="red--text">{{ this.messageNotification }}</span>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
            <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
              Xадгалаx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    totalParticipants: 0,
    loading: false,
    matrix: null,
    results: null,
    questions: null,
    createSoril: false,
    editedItem: {
      assignmentType: 2,
      difficulty: 0,
    },
    min: 0,
    max: 100,
    headers: [
      { text: "Soril No", value: "sorilIndex", width: "1%", sortable: false },
      { text: "No", value: "questionIndex", width: "1%" },
      { text: "A", value: "A", align: "center" },
      { text: "B", value: "B", align: "center" },
      { text: "C", value: "C", align: "center" },
      { text: "D", value: "D", align: "center" },
      { text: "E", value: "E", align: "center" },
      { text: "Correct %", value: "correctPercent", align: "center" },
    ],
    categories: null,
    selectedLessonGroup: null,
    assignments: null,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    messageNotification: null,
  }),

  computed: {
    filteredList() {
      var list = null;
      if (this.assignments) {
        list = [];
        for (var assignment of this.assignments) {
          for (var question of assignment.results) {
            if (this.editedItem.difficulty > 0) {
              if (question.correctPercent < this.editedItem.difficulty) {
                list.push(question);
              }
            } else {
              list.push(question);
            }
          }
        }
      }
      return list;
    },
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  watch: {
    selectedLessonGroup(val) {
      this.editedItem.selectedLessonCategory = val;
      fb.db
        .collection("assignmentsdatabase")
        .where("selectedLessonCategory.name", "==", val.name)
        .get()
        .then((docs) => {
          this.assignments = [];
          var counter = 1;
          docs.forEach(async (doc) => {
            let assignment = doc.data();
            assignment.id = doc.id;
            assignment.ref = doc.ref;
            assignment.results = [];

            await assignment.ref
              .collection("daalgavar-analyzed")
              .get()
              .then(async (docs) => {
                docs.forEach((doc) => {
                  let result = doc.data();
                  result.ref = doc.ref;
                  result.id = doc.id;
                  result.sorilIndex = "C" + counter;

                  result.correctPercent = (
                    (100 * result[result.correctAnswer]) /
                    this._getPercentOfCorrectAnswer(result)
                  ).toFixed(2);

                  assignment.results.push(result);
                });
              });

            this.assignments.push(assignment);
            counter++;
          });
          console.log(this.assignments);
        });
    },
  },
  created() {
    fb.db
      .collection("categories")
      .orderBy("name", "asc")
      .get()
      .then((docs) => {
        this.categories = [];
        docs.forEach((doc) => {
          let category = doc.data();
          category.ref = doc.ref;
          category.id = doc.id;
          category.name2 = category.name + " - " + category.lessonTypeName;
          this.categories.push(category);
        });
        console.log(this.categories);
      });
  },
  methods: {
    _getPercentOfCorrectAnswer(item) {
      var sum = 0;
      if (item.A) sum = sum + item.A;
      if (item.B) sum = sum + item.B;
      if (item.C) sum = sum + item.C;
      if (item.D) sum = sum + item.D;
      if (item.E) sum = sum + item.E;
      return sum;
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.selectedLessonCategory &&
        this.editedItem.difficulty &&
        this.editedItem.amountOfQuestions
      ) {
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        var x = await this.userData.ref.get();
        var firstName = null;
        if (x.data().firstName) {
          firstName = x.data().firstName;
        }
        if (this.zzschool != null) {
          var x2 = await fb.db.doc(this.zzschool).get();
          if (x2.exists) {
            this.editedItem.createdBySchoolName = x2.data().name;
            this.editedItem.createdBySchoolRef = x2.ref;

            this.editedItem.forAllSchool = false;
          }
        } else {
          this.editedItem.createdBySchoolName = null;
          this.editedItem.createdBySchoolRef = null;
          this.editedItem.forAllSchool = true;
        }

        this.editedItem.deleted = false;
        this.editedItem.setupped = false;
        this.editedItem.finished = false;
        this.editedItem.showingCorrectAnswers = false;
        this.editedItem.createdByRef = this.userData.ref;
        this.editedItem.createdAt = new Date();
        this.editedItem.createdByEmail = this.userData.email;
        this.editedItem.createdByFirstName = firstName;
        this.editedItem.createdByRole = this.userData.role;

        this.editedItem.currentYear = new Date().getFullYear();
        this.editedItem.currentMonth = new Date().getMonth() + 1;

        fb.db
          .collection("assignmentsdatabase")
          .add(this.editedItem)
          .then((docRef) => {
            console.log("Document xxxxx written!");

            this.createAssignment(docRef);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    createAssignment(docRef) {
      var questionIndex = 1;
      docRef.collection("categories").doc("1").set({
        abc: true,
        catIndex: 0,
        categoryScoreRequired: false,
        givingScorePerQuestion: false,
        name: "1",
        numberOfQuestions: this.editedItem.amountOfQuestions,
        totalScore: this.editedItem.amountOfQuestions,
        withKeys: false,
      });
      for (var assignment of this.assignments) {
        for (var question of assignment.results) {
          if (questionIndex <= this.editedItem.amountOfQuestions) {
            if (question.correctPercentage < this.editedItem.difficulty) {
              question.index = questionIndex;
              question.name = questionIndex;
              question.originalRef == undefined
                ? question.ref
                : (question.answered = false);
              question.answerLimit = 1;
              question.category = "1";
              question.countAnswers = 0;
              question.questionType = "Нэг сонголттой";
              question.score = 1;
              question.createdAt = new Date();
              question.answers = [
                {
                  name: "A",
                  correct: question.correctAnswer == "A" ? true : false,
                },
                {
                  name: "B",
                  correct: question.correctAnswer == "B" ? true : false,
                },
                {
                  name: "C",
                  correct: question.correctAnswer == "C" ? true : false,
                },
                {
                  name: "D",
                  correct: question.correctAnswer == "D" ? true : false,
                },
                {
                  name: "E",
                  correct: question.correctAnswer == "E" ? true : false,
                },
              ];
              question.ref.update({
                reUsedAmount: fb.firestore.FieldValue.increment(1),
              });
              docRef
                .collection("categories")
                .doc("1")
                .collection("questions")
                .doc(question.id)
                .set(question);
              console.log(docRef);
              questionIndex++;
            }
          } else {
            break;
          }
        }
      }
      this._closeEdit();
    },
    _closeEdit() {
      this.createSoril = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _getSum(result) {
      var sum = 0;
      var xx = 0;
      if (result.A == undefined) result.A = 0;
      if (result.B == undefined) result.B = 0;
      if (result.C == undefined) result.C = 0;
      if (result.D == undefined) result.D = 0;
      if (result.E == undefined) result.E = 0;

      sum = result.A + result.B + result.C + result.D + result.E;
      xx =
        result[result.correctAnswer] != undefined
          ? Number(result[result.correctAnswer])
          : 0;
      result.correctPercentage = ((xx / sum) * 100).toFixed(2);

      return ((xx / sum) * 100).toFixed(2);
    },
  },
};
</script>

<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}

.analiz table,
.analiz th,
.analiz td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

td {
  width: 30px;
  padding: 1px;
}
</style>
